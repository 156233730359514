import { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { Logout } from '../pages/auth/login/Logout'
import axios from 'axios'
export const GENERATE_TOKEN_URL = `${process.env.REACT_APP_API_URL}user/GenerateTokenUrl`

export function PrivateRoutes() {

  const params = new URLSearchParams(window.location.search);
  const redirect = params.get("redirect");

  if (redirect) {
    let payload = { 'projectId': redirect };
    axios.post(GENERATE_TOKEN_URL, payload ).then((response: any) => {
      window.location.href = response.data;
    })
      .catch((error) => {
      })
  }
  const PersonViewerPage = lazy(() => import('../pages/person/profile/PersonViewer'))
  const TenantViewerPage = lazy(() => import('../pages/tenant/TenantViewer'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/tenant' component={TenantViewerPage} />
        <Route path='/person' component={PersonViewerPage} />
        <Route path='/logout' component={Logout} />
        <Route path='/dashboard' component={DashboardWrapper} />
        <Redirect from='/auth' to='/person' />
        <Redirect exact from='/' to='/person' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}