export class UserModel {
    id!: string
    creationDate?: string
    modifiedDate?: string
    timestamp?: string
    fullName?: string
    email?: string
    mobilePhone?:string
    password?: string
    isApproved!: boolean
    isLockedOut!: boolean
    lastLoginDate?: Date
    passwordAttempts?: number
    signalRId?: string;
    tenantId?: string
}