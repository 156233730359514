/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Languages } from './Languages'
import { UserModel } from '../../../../app/models/user/UserModel'

const HeaderUserMenu: FC = () => {
  const intl = useIntl()

  let ls = localStorage.getItem('user');
  var user: UserModel = new UserModel();
  if (ls) {
    user = JSON.parse(ls) as UserModel
  }
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <i className="bi bi-person-square fs-2x text-primary"></i>
          </div>
          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {user?.fullName}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {user?.email ? user.email : user?.mobilePhone}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <Languages />

      <div className='menu-item px-5'>
        <Link to='/logout' className='menu-link px-5'>
          {intl.formatMessage({ id: 'TEXT.SIGN.OUT' })}
        </Link>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
