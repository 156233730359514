/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../_metronic/helpers'
import Swal from 'sweetalert2'
import axios from 'axios'

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}user/LoginByEmail`
const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;

export function LoginByEmail() {
  const intl = useIntl()

  const params = new URLSearchParams(window.location.search);
  let redirect = params.get("redirect");
  if (!redirect) { redirect = PROJECT_ID! }

  const registerByEmailUrl = '/auth/registerbyemail/?redirect=' + redirect;
  const loginByPasswordUrl = '/auth/loginbypassword/?redirect=' + redirect;
  const loginBySmsUrl = '/auth/loginbysms/?redirect=' + redirect;

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'FIELD.VALIDATION.FORMAT' }))
      .min(3, intl.formatMessage(
        { id: 'FIELD.VALIDATION.MIN' },
        { value: 3 }
      ))
      .max(50, intl.formatMessage(
        { id: 'FIELD.VALIDATION.MAX' },
        { value: 50 }
      ))
      .required(intl.formatMessage({ id: 'FIELD.VALIDATION.REQUIRED' }))
  })

  const initialValues = {
    email: ''
  }

  const [loading, setLoading] = useState(false)
  const [loginSuccess, setLoginSuccess] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true)
      setTimeout(() => {
        let email = values.email;
        axios.post(LOGIN_URL, {
          email,
          redirect
        }).then(() => {
          setLoading(false);
          setLoginSuccess(true);
        })
          .catch((error) => {
            setLoading(false)
            setSubmitting(false)
            Swal.fire({
              title: intl.formatMessage({ id: 'TEXT.ERROR.SMILEY' }),
              text: intl.formatMessage({ id: 'TEXT.ERROR' }),
              icon: 'error',
              confirmButtonText: intl.formatMessage({ id: 'TEXT.OK' }),
              confirmButtonColor: '#50CD89'
            })
          })
      }, 1000)
    },
  })

  return (
    <>
      {loginSuccess ? (
        <div className="alert alert-success d-flex align-items-center p-5 mb-10">
          <KTSVG
            path='/media/icons/duotune/general/gen048.svg'
            className='svg-icon svg-icon-2hx svg-icon-success me-4'
          />
          <div className="d-flex flex-column">
            <h4 className="mb-1 text-success">{intl.formatMessage({ id: 'TEXT.EMAIL.SENT' })}</h4>
            <span>{intl.formatMessage({ id: 'TEXT.EMAIL_BY_EMAIL.CONFIRMATION_DESCRIPTION' })}</span>
          </div>
        </div>
      ) : (
        <form
          className='form w-100'
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >
          {/* begin::Heading */}
          <div className='text-center mb-10'>
            <h1 className='text-dark mb-3'>{intl.formatMessage({ id: 'TEXT.LOGIN.CHOICE.TITLE' })}</h1>
            <div className='text-gray-400 fw-bold fs-4'>
              <Link to={registerByEmailUrl} className='link-primary fw-bolder'>
                {intl.formatMessage({ id: 'TEXT.WANT.TO_SUBSCRIBE' })}
              </Link>
            </div>
          </div>
          {/* begin::Heading */}

          {formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'TEXT.EMAIL' })}</label>
            <input
              placeholder={intl.formatMessage({ id: 'TEXT.EMAIL' })}
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                { 'is-invalid': formik.touched.email && formik.errors.email },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              type='email'
              name='email'
              autoComplete='on'
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <span className="text-danger" role='alert'>{formik.errors.email}</span>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Action */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.LOGIN' })}</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  {intl.formatMessage({ id: 'BUTTON.PLEASE_WAIT' })}{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Action */}
        </form>
      )}
      <div className='text-center'>

        <Link to={loginByPasswordUrl} className='btn btn-flex flex-center btn-light-success btn-lg w-100 my-5'>
          <KTSVG
            path='/media/icons/duotune/coding/cod004.svg'
            className='svg-icon svg-icon-2hx svg-icon-success me-4'
          />
          {intl.formatMessage({ id: 'TEXT.LOGIN.TYPE_2' })}
        </Link>
      </div>
    </>
  )
}