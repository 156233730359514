/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../_metronic/helpers'
import axios from 'axios'

export const REGISTER_URL = `${process.env.REACT_APP_API_URL}user/SubscribeByEmail`
const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;

const initialValues = {
  email: '',
  acceptTerms: false,
}

export function RegisterByEmail() {
  const intl = useIntl()

  const params = new URLSearchParams(window.location.search);
  let redirect = params.get("redirect");
  if (!redirect) { redirect = PROJECT_ID! }
  const loginUrl = '/auth/login/?redirect=' + redirect;

  const registrationSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'FIELD.VALIDATION.FORMAT' }))
      .min(3, intl.formatMessage(
        { id: 'FIELD.VALIDATION.MIN' },
        { value: 3 }
      ))
      .max(50, intl.formatMessage(
        { id: 'FIELD.VALIDATION.MAX' },
        { value: 50 }
      ))
      .required(intl.formatMessage({ id: 'FIELD.VALIDATION.REQUIRED' })),
    acceptTerms: Yup.bool().required(intl.formatMessage({ id: 'FIELD.VALIDATION.TERMS' })),
  })

  const [loading, setLoading] = useState(false)
  const [registerSuccess, setRegisterSuccess] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true)
      setTimeout(() => {
        let email = values.email;
        axios.post(REGISTER_URL, {
          email,
          redirect
        }).then(() => {
          setLoading(false);
          setRegisterSuccess(true);
        })
          .catch((error) => {
            setLoading(false)
            setSubmitting(false)
            Swal.fire({
              title: intl.formatMessage({ id: 'TEXT.ERROR.SMILEY' }),
              text: intl.formatMessage({ id: 'TEXT.ERROR' }),
              icon: 'error',
              confirmButtonText: intl.formatMessage({ id: 'TEXT.OK' }),
              confirmButtonColor: '#50CD89'
            })
          })
      }, 1000)
    },
  })

  return (
    <>
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>{intl.formatMessage({ id: 'TEXT.CREATE.ACCOUNT' })}</h1>
        {/* end::Title */}
        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          <Link to={loginUrl} className='link-primary fw-bolder' style={{ marginLeft: '5px' }}>
            {intl.formatMessage({ id: 'TEXT.HAVE.ACCOUNT' })}
          </Link>
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}
      {registerSuccess ? (
        <div className="alert alert-success d-flex align-items-center p-5 mb-10">
          <KTSVG
            path='/media/icons/duotune/general/gen048.svg'
            className='svg-icon svg-icon-2hx svg-icon-success me-4'
          />
          <div className="d-flex flex-column">
            <h4 className="mb-1 text-success">{intl.formatMessage({ id: 'TEXT.ACCOUNT.CREATED' })}</h4>
            <span>{intl.formatMessage({ id: 'TEXT.REGISTER_BY_EMAIL_CONFIRMATION.DESCRIPTION' })}</span>
          </div>
        </div>
      ) : (
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >

          {/* begin::Form group Email */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({ id: 'TEXT.EMAIL' })}</label>
            <input
              placeholder='Email'
              type='email'
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                { 'is-invalid': formik.touched.email && formik.errors.email },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className="text-danger" role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <div className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                id='kt_login_toc_agree'
                {...formik.getFieldProps('acceptTerms')}
              />
              <label
                className='form-check-label fw-bold text-gray-700 fs-6'
                htmlFor='kt_login_toc_agree'
              >
                {intl.formatMessage({ id: 'TEXT.AGREE_TERMS.PART1' })}{' '}
                <Link to='/terms' className='ms-1 link-primary'>
                  {intl.formatMessage({ id: 'TEXT.AGREE_TERMS.PART2' })}
                </Link>
                .
              </label>
              {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className="text-danger" role='alert'>{formik.errors.acceptTerms}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
            >
              {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.REGISTER' })}</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  {intl.formatMessage({ id: 'BUTTON.PLEASE_WAIT' })}{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Form group */}
        </form>
      )}
    </>
  )
}