import {useEffect} from 'react'

export function Logout() {
  useEffect(() => {
    localStorage.removeItem("user");
    document.location.reload()
  }, [])

  return (
    <></>
  )
}