/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../_metronic/helpers'
import Swal from 'sweetalert2'
import axios from 'axios'
export const LOGIN_URL = `${process.env.REACT_APP_API_URL}user/LoginBySms`
export const TOKEN_URL = `${process.env.REACT_APP_API_URL}user/ConfirmUserToken`

export function LoginBySms() {
  const intl = useIntl()

  const params = new URLSearchParams(window.location.search);
  const redirect = params.get("redirect");
  const registerByEmailUrl = redirect ? '/auth/registerbyemail/?redirect=' + encodeURIComponent(redirect) : '/auth/registerbyemail';
  const loginByEmailUrl = redirect ? '/auth/loginbyemail/?redirect=' + encodeURIComponent(redirect) : '/auth/loginbyemail';
  const loginByPasswordUrl = redirect ? '/auth/loginbypassword/?redirect=' + encodeURIComponent(redirect) : '/auth/loginbypassword';
  
  const loginSchema = Yup.object().shape({
    phone: Yup.string()
      .required(intl.formatMessage({ id: 'FIELD.VALIDATION.REQUIRED' }))
  })

  const smsCodeSchema = Yup.object().shape({
    code: Yup.string()
      .required(intl.formatMessage({ id: 'FIELD.VALIDATION.REQUIRED' }))
  })

  const loginInitialValues = {
    phone: ''
  }

  const smsCodeInitialValues = {
    code: ''
  }

  const [loading, setLoading] = useState(false)
  const [loginSuccess, setLoginSuccess] = useState(false)

  const formikSignin = useFormik({
    initialValues: loginInitialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true)
      setTimeout(() => {
        let phone = values.phone;
        axios.post(LOGIN_URL, {
          phone
        }).then(() => {
          setLoading(false);
          setLoginSuccess(true);
        })
          .catch((error) => {
            setLoading(false)
            setSubmitting(false)
            Swal.fire({
              title: intl.formatMessage({ id: 'TEXT.ERROR.SMILEY' }),
              text: intl.formatMessage({ id: 'TEXT.ERROR' }),
              icon: 'error',
              confirmButtonText: intl.formatMessage({ id: 'TEXT.OK' }),
              confirmButtonColor: '#50CD89'
            })
          })
      }, 1000)
    },
  })

  const formikCodeSms = useFormik({
    initialValues: smsCodeInitialValues,
    validationSchema: smsCodeSchema,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true)
      setTimeout(() => {
        let value = values.code;
        axios.post(TOKEN_URL, {
          value,
        }).then((response: any) => {
          setLoading(false);
          localStorage.setItem("user", JSON.stringify(response.data));
          setTimeout(() => {
            const params = new URLSearchParams(window.location.search);
            const redirect = params.get("redirect");
            window.location.href = redirect ? '/?redirect=' + encodeURIComponent(redirect) : '/';
          }, 3000)
        })
          .catch((error) => {
            setLoading(false)
            setSubmitting(false)
            Swal.fire({
              title: intl.formatMessage({ id: 'TEXT.ERROR.SMILEY' }),
              text: intl.formatMessage({ id: 'TEXT.ERROR' }),
              icon: 'error',
              confirmButtonText: intl.formatMessage({ id: 'TEXT.OK' }),
              confirmButtonColor: '#50CD89'
            })
          })
      }, 1000)
    },
  })

  return (
    <>
      {loginSuccess ? (
          <form
            className='form w-100'
            onSubmit={formikCodeSms.handleSubmit}
            noValidate
            id='kt_login_code_sms_form'
          >
            {/* begin::Heading */}
            <div className='text-center mb-10'>
              <h1 className='text-dark mb-3'>{intl.formatMessage({ id: 'TEXT.LOGIN.CHOICE.TITLE' })}</h1>
              <div className='text-gray-400 fw-bold fs-4'>
                <Link to={registerByEmailUrl} className='link-primary fw-bolder'>
                  {intl.formatMessage({ id: 'TEXT.WANT.TO_SUBSCRIBE' })}
                </Link>
              </div>
            </div>
            {/* begin::Heading */}

            {formikCodeSms.status && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formikCodeSms.status}</div>
              </div>
            )}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'TEXT.SMS_CODE' })}</label>
              <input
                placeholder={intl.formatMessage({ id: 'TEXT.SMS_CODE' })}
                {...formikCodeSms.getFieldProps('code')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  { 'is-invalid': formikCodeSms.touched.code && formikCodeSms.errors.code },
                  {
                    'is-valid': formikCodeSms.touched.code && !formikCodeSms.errors.code,
                  }
                )}
                type='text'
                name='code'
                autoComplete='on'
              />
              {formikCodeSms.touched.code && formikCodeSms.errors.code && (
                <div className='fv-plugins-message-container'>
                  <span className="text-danger" role='alert'>{formikCodeSms.errors.code}</span>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Action */}
            <div className='text-center'>
              <button
                type='submit'
                id='kt_code_sms_submit'
                className='btn btn-lg btn-primary w-100 mb-5'
                disabled={formikCodeSms.isSubmitting || !formikCodeSms.isValid}
              >
                {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.LOGIN' })}</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    {intl.formatMessage({ id: 'BUTTON.PLEASE_WAIT' })}{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Action */}
          </form>
      ) : (
        <form
          className='form w-100'
          onSubmit={formikSignin.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >
          {/* begin::Heading */}
          <div className='text-center mb-10'>
            <h1 className='text-dark mb-3'>{intl.formatMessage({ id: 'TEXT.LOGIN.CHOICE.TITLE' })}</h1>
            <div className='text-gray-400 fw-bold fs-4'>
              <Link to={registerByEmailUrl} className='link-primary fw-bolder'>
                {intl.formatMessage({ id: 'TEXT.WANT.TO_SUBSCRIBE' })}
              </Link>
            </div>
          </div>
          {/* begin::Heading */}

          {formikSignin.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formikSignin.status}</div>
            </div>
          )}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'TEXT.MOBILE_PHONE' })}</label>
            <input
              placeholder={intl.formatMessage({ id: 'TEXT.MOBILE_PHONE' })}
              {...formikSignin.getFieldProps('phone')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                { 'is-invalid': formikSignin.touched.phone && formikSignin.errors.phone },
                {
                  'is-valid': formikSignin.touched.phone && !formikSignin.errors.phone,
                }
              )}
              type='text'
              name='phone'
              autoComplete='on'
            />
            {formikSignin.touched.phone && formikSignin.errors.phone && (
              <div className='fv-plugins-message-container'>
                <span className="text-danger" role='alert'>{formikSignin.errors.phone}</span>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Action */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={formikSignin.isSubmitting || !formikSignin.isValid}
            >
              {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.LOGIN' })}</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  {intl.formatMessage({ id: 'BUTTON.PLEASE_WAIT' })}{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Action */}
        </form>
      )}
      <div className='text-center'>
        <Link to={loginByEmailUrl} className='btn btn-flex flex-center btn-light-primary btn-lg w-100 mb-5'>
          <KTSVG
            path='/media/icons/duotune/communication/com010.svg'
            className='svg-icon svg-icon-2hx svg-icon-primary me-4'
          />
          {intl.formatMessage({ id: 'TEXT.LOGIN.TYPE_1' })}
        </Link>

        <Link to={loginByPasswordUrl} className='btn btn-flex flex-center btn-light-success btn-lg w-100 mb-5'>
          <KTSVG
            path='/media/icons/duotune/coding/cod004.svg'
            className='svg-icon svg-icon-2hx svg-icon-success me-4'
          />
          {intl.formatMessage({ id: 'TEXT.LOGIN.TYPE_2' })}
        </Link>
      </div>
    </>
  )
}