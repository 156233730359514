/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../_metronic/helpers'

export function LoginChoice() {
  const intl = useIntl();

  const params = new URLSearchParams(window.location.search);
  const redirect = params.get("redirect");
  const registerByEmailUrl = redirect ? '/auth/registerbyemail/?redirect=' + encodeURIComponent(redirect) : '/auth/registerbyemail';
  const loginByEmailUrl = redirect ? '/auth/loginbyemail/?redirect=' + encodeURIComponent(redirect) : '/auth/loginbyemail';
  const loginByPasswordUrl = redirect ? '/auth/loginbypassword/?redirect=' + encodeURIComponent(redirect) : '/auth/loginbypassword';
  const loginBySmsUrl = redirect ? '/auth/loginbysms/?redirect=' + encodeURIComponent(redirect) : '/auth/loginbysms';

  return (
    <>
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>{intl.formatMessage({ id: 'TEXT.LOGIN.CHOICE.TITLE' })}</h1>
        <div className='text-gray-400 fw-bold fs-4'>
          <Link to={registerByEmailUrl} className='link-primary fw-bolder'>
            {intl.formatMessage({ id: 'TEXT.WANT.TO_SUBSCRIBE' })}
          </Link>
        </div>
        <div className='text-gray-400 fs-6 mt-5'>
          {intl.formatMessage({ id: 'TEXT.LOGIN.CHOICE.DESCRIPTION' })}
        </div>
      </div>
      {/* begin::Heading */}
      <div className='text-center'>
        <Link to={loginByEmailUrl} className='btn btn-flex flex-center btn-light-primary btn-lg w-100 mb-5'>
          <KTSVG
            path='/media/icons/duotune/communication/com010.svg'
            className='svg-icon svg-icon-2hx svg-icon-primary me-4'
          />
          {intl.formatMessage({ id: 'TEXT.LOGIN.TYPE_1' })}
        </Link>

        <Link to={loginByPasswordUrl} className='btn btn-flex flex-center btn-light-success btn-lg w-100 mb-5'>
          <KTSVG
            path='/media/icons/duotune/coding/cod004.svg'
            className='svg-icon svg-icon-2hx svg-icon-success me-4'
          />
          {intl.formatMessage({ id: 'TEXT.LOGIN.TYPE_2' })}
        </Link>

        {/*<Link to={loginBySmsUrl} className='btn btn-flex flex-center btn-light-info btn-lg w-100 mb-5'>
          <KTSVG
            path='/media/icons/duotune/communication/com012.svg'
            className='svg-icon svg-icon-2hx svg-icon-info me-4'
          />
          {intl.formatMessage({ id: 'TEXT.LOGIN.TYPE_3' })}
        </Link>*/}
      </div>
    </>
  )
}