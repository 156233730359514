export default function setupAxios(axios: any, store: any) {
  const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;
  axios.interceptors.request.use(
    (config: any) => {

      config.headers.ProjectId = PROJECT_ID;

      let user = localStorage.getItem('user');
      let language = localStorage.getItem('iTrustLanguage');

      if (user) {
        config.headers.Authorization = `Bearer ${JSON.parse(user).accessToken}`
        config.headers.iTrustLanguage = language ? language : 'fr'
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}
