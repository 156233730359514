/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'
import axios from 'axios'
import Swal from 'sweetalert2'
import { KTSVG } from '../../../../_metronic/helpers'
export const LOGIN_URL = `${process.env.REACT_APP_API_URL}user/LoginByPassword`

export function LoginByPassword() {
  const intl = useIntl()

  const params = new URLSearchParams(window.location.search);
  const redirect = params.get("redirect");
  const registerByEmailUrl = redirect ? '/auth/registerbyemail/?redirect=' + encodeURIComponent(redirect) : '/auth/registerbyemail';
  const loginByEmailUrl = redirect ? '/auth/loginbyemail/?redirect=' + encodeURIComponent(redirect) : '/auth/loginbyemail';
  const loginBySmsUrl = redirect ? '/auth/loginbysms/?redirect=' + encodeURIComponent(redirect) : '/auth/loginbysms';

  const loginSchema = Yup.object().shape({
    login: Yup.string()
      .required(intl.formatMessage({ id: 'FIELD.VALIDATION.REQUIRED' })),
    password: Yup.string()
      .required(intl.formatMessage({ id: "FIELD.VALIDATION.REQUIRED" }))
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        intl.formatMessage({ id: "VALIDATION.PASSWORD.FORMAT" })
      )
  })

  const initialValues = {
    login: '',
    password: '',
  }

  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true)
      setTimeout(() => {
        let login = values.login;
        let password = values.password;
        axios.post(LOGIN_URL, { login, password }).then((response: any) => {
          setLoading(false);
          localStorage.setItem("user", JSON.stringify(response.data));
          setTimeout(() => {
            const params = new URLSearchParams(window.location.search);
            const redirect = params.get("redirect");
            window.location.href = redirect ? '/?redirect=' + encodeURIComponent(redirect) : '/';
          }, 3000)
        })
          .catch((error) => {
            setLoading(false)
            setSubmitting(false)
            Swal.fire({
              title: intl.formatMessage({ id: 'TEXT.ERROR.SMILEY' }),
              text: intl.formatMessage({ id: 'TEXT.ERROR' }),
              icon: 'error',
              confirmButtonText: intl.formatMessage({ id: 'TEXT.OK' }),
              confirmButtonColor: '#50CD89'
            })
          })
      }, 1000)
    },
  })

  return (
    <>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {/* begin::Heading */}
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>{intl.formatMessage({ id: 'TEXT.LOGIN.CHOICE.TITLE' })}</h1>
          <div className='text-gray-400 fw-bold fs-4'>
            <Link to={registerByEmailUrl} className='link-primary fw-bolder'>
              {intl.formatMessage({ id: 'TEXT.WANT.TO_SUBSCRIBE' })}
            </Link>
          </div>
        </div>
        {/* begin::Heading */}

        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        {/* begin::Form group */}
        <div className='fv-row mb-5'>
          <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'TEXT.LOGIN' })}</label>
          <input
            placeholder={intl.formatMessage({ id: 'TEXT.LOGIN' })}
            {...formik.getFieldProps('login')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.login && formik.errors.login },
              {
                'is-valid': formik.touched.login && !formik.errors.login,
              }
            )}
            type='text'
            name='login'
            autoComplete='on'
          />
          {formik.touched.login && formik.errors.login && (
            <div className='fv-plugins-message-container'>
              <span className="text-danger" role='alert'>{formik.errors.login}</span>
            </div>
          )}
        </div>
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'TEXT.PASSWORD' })}</label>
          <input
            placeholder={intl.formatMessage({ id: 'TEXT.PASSWORD' })}
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.password && formik.errors.password },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
            type='password'
            name='password'
            autoComplete='on'
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <span className="text-danger" role='alert'>{formik.errors.password}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Action */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.LOGIN' })}</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                {intl.formatMessage({ id: 'BUTTON.PLEASE_WAIT' })}{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}
      </form>

      <div className='text-center'>

        <Link to={loginByEmailUrl} className='btn btn-flex flex-center btn-light-primary btn-lg w-100 mb-5'>
          <KTSVG
            path='/media/icons/duotune/communication/com010.svg'
            className='svg-icon svg-icon-2hx svg-icon-primary me-4'
          />
          {intl.formatMessage({ id: 'TEXT.LOGIN.TYPE_1' })}
        </Link>
      </div>
    </>
  )
}