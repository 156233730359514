import {toAbsoluteUrl} from '../../../helpers'
import { useIntl } from 'react-intl';

export function FallbackView() {
  const intl = useIntl()
  return (
    <div className='fallback-screen'>
      <img src={toAbsoluteUrl('/media/logos/logo-white-transparent.png')} alt='iTrust logo' className='h-70px' />
      <span className='text-white'>{intl.formatMessage({ id: 'TEXT.LOADING' })}</span>
    </div>
  )
}
