/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../_metronic/helpers'
import axios from 'axios'

export const CONFIRM_TOKEN_URL = `${process.env.REACT_APP_API_URL}user/ConfirmUserToken`
const token = window.location.href.substring(
  window.location.href.lastIndexOf("/") + 1
);

export function ConfirmToken(props: any) {
  const intl = useIntl()

  const [loading, setLoading] = useState(false)
  const [confirmError, setConfirmError] = useState(false)
  const [confirmSuccess, setConfirmSuccess] = useState(false)

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      axios.post(CONFIRM_TOKEN_URL, {
        token
      }).then((response: any) => {
        setLoading(false);
        setConfirmSuccess(true);
        localStorage.setItem("user", JSON.stringify(response.data));
        setTimeout(() => {
          const params = new URLSearchParams(window.location.search);
          const redirect = params.get("redirect");
          window.location.href = redirect ? '/?redirect=' + encodeURIComponent(redirect) : '/';
        }, 3000)
      })
        .catch((error) => {
          setLoading(false)
          setConfirmError(true)
        })
    }, 1000)
  }, []);

  return (
    <>
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>{intl.formatMessage({ id: 'TEXT.ACCOUNT.CONFIRM' })}</h1>
        {/* end::Title */}{/* begin::Link */}
        {/* end::Link */}
      </div>
      {/* end::Heading */}
      {loading ? (
        <div className="alert alert-primary d-flex align-items-center p-5 mb-10">
          <KTSVG
            path='/media/icons/duotune/abstract/abs051.svg'
            className='svg-icon svg-icon-2hx svg-icon-primary me-4'
          />
          <span className='spinner-border spinner-border-sm align-middle ms-2' style={{ position: 'absolute', left: 19, color: 'white' }}></span>
          <div className="d-flex flex-column">
            <h4 className="mb-1 text-primary">{intl.formatMessage({ id: 'BUTTON.PLEASE_WAIT' })}</h4>
            <span>{intl.formatMessage({ id: 'TEXT.ACCOUNT.CONFRIM.DESCRIPTION' })}</span>
          </div>
        </div>
      ) : (
        confirmSuccess ? (
          <div className="alert alert-success d-flex align-items-center p-5 mb-10">
            <KTSVG
              path='/media/icons/duotune/general/gen048.svg'
              className='svg-icon svg-icon-2hx svg-icon-success me-4'
            />
            <div className="d-flex flex-column">
              <h4 className="mb-1 text-success">{intl.formatMessage({ id: 'TEXT.SUCCESS.SMILEY' })}</h4>
              <span>{intl.formatMessage({ id: 'TEXT.ACCOUNT.CONFRIM.SUCCESS' })}</span>
            </div>
          </div>
        ) : (
          <div className="alert alert-danger d-flex align-items-center p-5 mb-10">
            <KTSVG
              path='/media/icons/duotune/general/gen050.svg'
              className='svg-icon svg-icon-2hx svg-icon-danger me-4'
            />
            <div className="d-flex flex-column">
              <h4 className="mb-1 text-danger">{intl.formatMessage({ id: 'TEXT.ERROR.SMILEY' })}</h4>
              <span>{intl.formatMessage({ id: 'TEXT.ACCOUNT.CONFRIM.ERROR' })}</span>
            </div>
          </div>
        )
      )}
    </>
  )
}